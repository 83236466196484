import * as React from "react";

type UseLayout = () => [
  setShowModal: (value: boolean) => void,
  showModal: boolean
];
const LayoutContext = React.createContext(undefined);

const useLayout: UseLayout = () => {
  const context = React.useContext(LayoutContext);
  if (!context) {
    throw new Error(`useLayout must be used within a LayoutProvider`);
  }

  return context;
};

function LayoutProvider(props: any) {
  const [showModal, setShowModalR] = React.useState(false);

  const setShowModal = (showModal: boolean) => setShowModalR(showModal);
  const value = React.useMemo(() => [setShowModal, showModal], [setShowModal]);

  return <LayoutContext.Provider value={value} {...props} />;
}

export { LayoutProvider, useLayout };
