// ./gatsby-browser.js
import * as React from "react";

import { LayoutProvider } from "./src/components/Layout/layout-context";
import "./src/styles/global.css";
import "react-slidy/lib/styles.css";

export const wrapRootElement = ({ element }) => (
  <LayoutProvider>{element}</LayoutProvider>
);
